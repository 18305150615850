@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
    /* Sayfa kaydırma davranışını kontrol eder */
    overscroll-behavior-y: none;
    /* Safari'de adres çubuğunun otomatik olarak küçülmesini engeller */
}

@layer utilities {
    .shadow-opacity {
        box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.5);
        /* İstenilen opaklık seviyesini buradan ayarlayabilirsiniz */
    }
}


@layer utilities {
    @variants responsive {

        /* Hide scrollbar for Chrome, Safari, and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }


        /* Hide scrollbar for IE, Edge, and Firefox */
        .no-scrollbar {
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */
        }
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

#rotate-image {
    animation: rotate 200s linear infinite;
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&family=IBM+Plex+Sans:wght@100;400;600&display=swap');

@font-face {
    font-family: 'IBMPlex-Bold';
    src: url('./fonts/IBMPlexSans-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlex-Regular';
    src: url('./fonts/IBMPlexSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlex-Thin';
    src: url('./fonts/IBMPlexSans-Thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlex-Medium';
    src: url('./fonts/IBMPlexSans-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


.custom-font {
    font-family: 'IBMPlex-Regular';
}

.custom-font-bold {
    font-family: 'IBMPlex-Bold';
}

.custom-font-light {
    font-family: 'IBMPlex-Thin';
}

.custom-font-medium {
    font-family: 'IBMPlex-Medium';
}

.mirror-y {
    transform: scaleX(-1);
}

.custom-font-light {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    /* Normal (400) weight */
}

.custom-font {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    /* Semi-Bold (600) weight */
}

.custom-font-bold {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    /* Bold (700) weight */
}

.custom-rounded {
    border-radius: 2.5rem;
    /* Özel border radius değeri, ihtiyacınıza göre ayarlayın */
}

.scrollbar-hide {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    /* Firefox için */
}

.container {
    width: 100%;
    overflow-x: hidden;
}

.horizontal-scrolling-items {
    display: flex;
    font-size: 40px;
}

.horizontal-scrolling-items__item {
    white-space: nowrap;
}

@keyframes infiniteScroll {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-50%);
    }
}

.horizontal-scrolling-items {
    animation-name: infiniteScroll;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

div span a {
    color: blue;
    text-decoration: none;
}

div span a:hover {
    text-decoration: underline;
}